import { useEffect, useState } from "react";
import moment from "moment";

export const DateTimeSection = (props) => {
  const { pickupDate, setPickupDate, pickupTime, setPickupTime, isFormValidated } = props;
  const [minDate, setMinDate] = useState('');
  useEffect(() => {
    const getCurrentDate = () => {
      return moment().format('YYYY-MM-DD')
    };
    setMinDate(getCurrentDate());
  }, []);
  return (
    <>
      <div className="mb-2">
        <label htmlFor="pickupDate" className="form-label custom-label">
          Pickup Date
        </label>
        <input
          type="date"
          className="form-control"
          id="pickupDate"
          placeholder=""
          min={minDate}
          value={pickupDate}
          onChange={(e) => setPickupDate(e.target.value)}
        />
        {isFormValidated && !pickupDate && (
          <p className="distance-style">Please select the pickup date</p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="pickupTime" className="form-label custom-label">
          Pickup Time
        </label>
        <input
          type="time"
          className="form-control"
          id="pickupTime"
          placeholder=""
          value={pickupTime}
          onChange={(e) => setPickupTime(e.target.value)}
        />
        {isFormValidated && !pickupTime && (
          <p className="distance-style">Please select the pickup time</p>
        )}
      </div>
    </>
  );
};
