import { Link, useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { nonAuthPostAxios } from "../../Utilities/commonAxios";
import { manageLoader } from "../../Reduxstore/loaderSlice";
import { useDispatch, useSelector } from 'react-redux';
import { authLogin } from "../../Reduxstore/authSlice";

const Register = () => {
    let isBooked = useSelector(state => state.booking)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const registerForm = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            fullname: '',
            email: '',
            phoneNumber: '',
            password: '',
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required("Please Enter Full name"),
            email: Yup.string().required("Please Enter email"),
            phoneNumber: Yup.string().max(13,'Mobile Number must be maximum of 13 characters').required("Please Enter phone number"),
            password: Yup.string().required("Please Enter password")
        }),
        onSubmit: async (values) => {
            if (!registerForm.dirty) {
                toast.error('No data has changed!')
            } else {
                dispatch(manageLoader(true));
                const { data: registerResponse } = await nonAuthPostAxios({
                    url: `${process.env.REACT_APP_API_URL}/customers_register`,
                    data: {
                        customer_name: values.fullname,
                        email_id: values.email,
                        mobile_number: values.phoneNumber,
                        password: values.password
                    }
                })
                dispatch(manageLoader(false));
                if (registerResponse.message === "register Successfully!") {
                    toast.success(registerResponse.message)
                    let loginResponseData = await dispatch(authLogin({ user_id: values.email, password: values.password }));
                    dispatch(manageLoader(false));
                    if (loginResponseData?.payload?.api_success) {
                        if (Object.keys(isBooked?.inCompleteBookingDetails).length > 0 && isBooked?.isBookingInitiated) {
                            navigate('/confirm-booking')
                        } else {
                            toast.success("Login successfully!")
                            navigate('/my-bookings')
                        }
                    } else {
                        toast.error(loginResponseData?.payload)
                    }
                } else {
                    toast.error(registerResponse.message)
                }
            }
        }
    });
    return (
        <>
            <h4>Welcome To The Movers Online</h4>
            <form onSubmit={registerForm.handleSubmit}>
                <div className="mb-2">
                    <label htmlFor="fullname" className="form-label text-start custom-label">Full Name</label>
                    <input type="text" className="form-control custom-input" id="fullname" name="fullname" aria-describedby="fullname" values={registerForm.values.fullname} onChange={registerForm.handleChange} />
                </div>
                {registerForm.errors.fullname && (
                    <div className="text-danger">{registerForm.errors.fullname}</div>
                )}
                <div className="mb-2">
                    <label htmlFor="email" className="form-label text-start custom-label">Email</label>
                    <input type="email" className="form-control custom-input" id="email" name="email" aria-describedby="email" values={registerForm.values.email} autoComplete='off' onChange={registerForm.handleChange} />
                </div>
                {registerForm.errors.email && (
                    <div className="text-danger">{registerForm.errors.email}</div>
                )}
                <label htmlFor="exampleInputEmail1" className="form-label text-start custom-label">Mobile Number</label>
                <div className="input-group mb-2 form-control custom-input">
                    <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="MY"
                        name="phoneNumber"
                        value={registerForm.values.phoneNumber}
                        limitMaxLength={13}
                        onChange={(value) => registerForm.setFieldValue('phoneNumber', value)} />
                </div>
                {registerForm.errors.phoneNumber && (
                    <div className="text-danger">{registerForm.errors.phoneNumber}</div>
                )}
                <div className="mb-2">
                    <label htmlFor="password" className="form-label text-start custom-label">Password</label>
                    <input type="password" className="form-control custom-input" id="password" name="password" aria-describedby="password" values={registerForm.values.password} onChange={registerForm.handleChange} />
                </div>
                {registerForm.errors.password && (
                    <div className="text-danger">{registerForm.errors.password}</div>
                )}
                <div className='register-btn'>
                    <button className='btn btn-success register-btn-style' type="submit">Register</button>
                </div>
                <div className='login-style'>
                    I have an account - <Link to={"/login"} className='login-text'>Login</Link>
                </div>
            </form>
        </>
    )
}

export default Register