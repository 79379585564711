import _ from "lodash";
import React, { useState, useEffect } from "react";
import { getAxios } from "../../Utilities/commonAxios";
import { useDispatch,useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { manageLoader } from "../../Reduxstore/loaderSlice";
import { bookingSubmitted } from "../../Reduxstore/bookingSlice";

const MyBookingLists = () => {
  const dispatch = useDispatch();
  let isAuthenticated = useSelector((state) => state.auth);
  let isBooked = useSelector((state) => state.booking);
  if(isBooked.isBookingInitiated){
    dispatch(bookingSubmitted("completed"));
  }
  const [bookingLists, setBookingLists] = useState([]);
  const getBookingListAPI = async () => {
    dispatch(manageLoader(true));
    await getAxios({
      url: `${process.env.REACT_APP_API_URL}/booking_list?customer_id=${isAuthenticated?.postItems?.Customer?.id}`,
    }).then((response) => {
      dispatch(manageLoader(false));
      setBookingLists(response?.data)}
    );
  };
  useEffect(() => {
    getBookingListAPI();
  }, []);
  // available, completed, pending, accepted
  const [availableBookings, setAvailableBookings] = useState([]);
  const [acceptedBookings, setAcceptedBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const [pendingBookings, setPendingBookings] = useState([]);
  useEffect(() => {
    if (bookingLists?.Api_success) {
      setAvailableBookings(
        _.filter(bookingLists?.bookings_list, ["booking_status", "Available"])
      );
      setAcceptedBookings(
        _.filter(bookingLists?.bookings_list, ["booking_status", "Accepted"])
      );
      setCompletedBookings(
        _.filter(bookingLists?.bookings_list, ["booking_status", "Completed"])
      );
      setPendingBookings(
        _.filter(bookingLists?.bookings_list, [
          "booking_status",
          "Cancel Request Pending",
        ])
      );
    }
  }, [bookingLists]);
  return (
    <>
      <section className="common-top-style mybooking-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-12">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-available-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-available"
                    type="button"
                    role="tab"
                    aria-controls="pills-available"
                    aria-selected="false"
                  >
                    Available Bookings
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-accepted-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-accepted"
                    type="button"
                    role="tab"
                    aria-controls="pills-accepted"
                    aria-selected="true"
                  >
                    Accepted Bookings
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-pending-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-pending"
                    type="button"
                    role="tab"
                    aria-controls="pills-pending"
                    aria-selected="false"
                  >
                    Pending Bookings
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-completed-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-completed"
                    type="button"
                    role="tab"
                    aria-controls="pills-completed"
                    aria-selected="false"
                  >
                    Completed Bookings
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade"
                  id="pills-accepted"
                  role="tabpanel"
                  aria-labelledby="pills-accepted-tab"
                  tabIndex="0"
                >
                  <div className="row inside-booking-top">
                    {acceptedBookings &&
                      acceptedBookings.map((acceptedBooking, index) => (
                        <>
                          <div
                            className="col-xl-4 col-12 d-flex align-items-stretch mb-4"
                            key={index}
                          >
                              <div className="card custom-booking">
                              <Link to={`/mybookingdetails/${btoa(acceptedBooking.id)}`} className="overall-link-textstyle">
                                <div className="card-body card-padding-style">
                                  <h5 className="card-title card-title">
                                    {acceptedBooking?.booking_status} Booking
                                  </h5>
                                  <h4 className="sub-card-title">
                                    MYR {acceptedBooking?.total_amount}
                                  </h4>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Pickup Address
                                  </p>
                                  <p className="card-address-style">
                                    {acceptedBooking?.pickup_address}
                                  </p>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Drop off Address
                                  </p>
                                  <p className="card-address-style">
                                    {acceptedBooking?.drop_off_address}
                                  </p>
                                  <p className="card-time-style">
                                    Time:{" "}
                                    {moment(
                                      acceptedBooking?.booking_date_time
                                    ).format("hh:mm A")}
                                    {` `} Date:{" "}
                                    {moment(
                                      acceptedBooking?.booking_date_time
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                                </Link>
                              </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="pills-available"
                  role="tabpanel"
                  aria-labelledby="pills-available-tab"
                >
                  <div className="row inside-booking-top">
                    {availableBookings &&
                      availableBookings.map((availableBooking, index) => (
                        <>
                          <div
                            className="col-xl-4 col-12 d-flex align-items-stretch mb-4"
                            key={index}
                          >
                            
                              <div className="card custom-booking">
                              <Link to={`/mybookingdetails/${btoa(availableBooking.id)}`} className="overall-link-textstyle">
                                <div className="card-body card-padding-style">
                                  <h5 className="card-title card-title">
                                    {availableBooking?.booking_status} Booking
                                  </h5>
                                  <h4 className="sub-card-title">
                                    MYR {availableBooking?.total_amount}
                                  </h4>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Pickup Address
                                  </p>
                                  <p className="card-address-style">
                                    {availableBooking?.pickup_address}
                                  </p>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Drop off Address
                                  </p>
                                  <p className="card-address-style">
                                    {availableBooking?.drop_off_address}
                                  </p>
                                  <p className="card-time-style">
                                    Time:{" "}
                                    {moment(
                                      availableBooking?.booking_date_time
                                    ).format("hh:mm A")}
                                    {` `} Date:{" "}
                                    {moment(
                                      availableBooking?.booking_date_time
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                                </Link>
                              </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-pending"
                  role="tabpanel"
                  aria-labelledby="pills-pending-tab"
                >
                  <div className="row inside-booking-top">
                    {pendingBookings &&
                      pendingBookings.map((pendingBooking, index) => (
                        <>
                          <div
                            className="col-xl-4 col-12 d-flex align-items-stretch mb-4"
                            key={index}
                          >
                              <div className="card custom-booking">
                              <Link to={`/mybookingdetails/${btoa(pendingBooking.id)}`} className="overall-link-textstyle">
                                <div className="card-body card-padding-style">
                                  <h5 className="card-title card-title">
                                    {pendingBooking?.booking_status} Booking
                                  </h5>
                                  <h4 className="sub-card-title">
                                    MYR {pendingBooking?.total_amount}
                                  </h4>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Pickup Address
                                  </p>
                                  <p className="card-address-style">
                                    {pendingBooking?.pickup_address}
                                  </p>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Drop off Address
                                  </p>
                                  <p className="card-address-style">
                                    {pendingBooking?.drop_off_address}
                                  </p>
                                  <p className="card-time-style">
                                    Time:{" "}
                                    {moment(
                                      pendingBooking?.booking_date_time
                                    ).format("hh:mm A")}
                                    {` `} Date:{" "}
                                    {moment(
                                      pendingBooking?.booking_date_time
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                                </Link>
                              </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-completed"
                  role="tabpanel"
                  aria-labelledby="pills-completed-tab"
                >
                  <div className="row inside-booking-top">
                    {completedBookings &&
                      completedBookings.map((completedBooking, index) => (
                        <>
                          <div
                            className="col-xl-4 col-12 d-flex align-items-stretch mb-4"
                            key={index}
                          >
                              <div className="card custom-booking">
                              <Link to={`/mybookingdetails/${btoa(completedBooking.id)}`} className="overall-link-textstyle">
                                <div className="card-body card-padding-style">
                                  <h5 className="card-title card-title">
                                    {completedBooking?.booking_status} Booking
                                  </h5>
                                  <h4 className="sub-card-title">
                                    MYR {completedBooking?.total_amount}
                                  </h4>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Pickup Address
                                  </p>
                                  <p className="card-address-style">
                                    {completedBooking?.pickup_address}
                                  </p>
                                  <p className="card-text">
                                    <i
                                      className="fa fa-map-marker me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Drop off Address
                                  </p>
                                  <p className="card-address-style">
                                    {completedBooking?.drop_off_address}
                                  </p>
                                  <p className="card-time-style">
                                    Time:{" "}
                                    {moment(
                                      completedBooking?.booking_date_time
                                    ).format("hh:mm A")}
                                    {` `} Date:{" "}
                                    {moment(
                                      completedBooking?.booking_date_time
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                                </Link>
                              </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyBookingLists;
