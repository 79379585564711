import { Link } from "react-router-dom";
import Uploadimg from "../../img/profile-img1.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { ConvertToBase64 } from "../../Utilities/convertToBase64";
import { nonAuthPostAxios } from "../../Utilities/commonAxios";
import { useSelector } from "react-redux";

const Settings = () => {
  let isAuthenticated = useSelector(state => state.auth)
  const profileForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (isAuthenticated?.postItems?.Customer?.name) ? (isAuthenticated?.postItems?.Customer?.name) : "",
      email: (isAuthenticated?.postItems?.Customer?.email) ? (isAuthenticated?.postItems?.Customer?.email) : "",
      mobile: (isAuthenticated?.postItems?.Customer?.mobile_number) ? (isAuthenticated?.postItems?.Customer?.mobile_number) : "",
      profile: "",
      profileExistPath: (isAuthenticated?.postItems?.Customer?.profile_image) ? (`https://app.themovers.com.my/public/customer_uploads/${isAuthenticated?.postItems?.Customer?.profile_image}`) : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter the name"),
      email: Yup.string().required("Please enter the email"),
      mobile: Yup.string().required("Please enter the mobile"),
      profile: Yup.string().required("Please chose profile image"),
    }),
    onSubmit: async (values) => {
      if (!profileForm.dirty) {
        toast.error('No data has changed!');
      } else {
        let { data: updateProfileResponse } = await nonAuthPostAxios({
          url: `${process.env.REACT_APP_API_URL}/customer_update_details`,
          data: {
            "id": isAuthenticated?.postItems?.Customer?.id,
            "customer_name": values.name,
            "email_id": values.email,
            "mobile_number": values.mobile
          }
        })
        if (updateProfileResponse?.Api_success) {
          let base64Image = await ConvertToBase64(values.profile);
          let { data: uploadImageResponse } = await nonAuthPostAxios({
            url: `${process.env.REACT_APP_API_URL}/customer_profile_upload`,
            data: {
              "id": isAuthenticated?.postItems?.Customer?.id,
              "ProfileUpload": base64Image
            }
          })
          if (!uploadImageResponse?.Api_success) {
            toast.error(uploadImageResponse?.api_message);
          } else {
            toast.success(uploadImageResponse?.api_message);
          }
        } else {
          toast.error('Unable to update profile!');
        }
      }
    },
  });
  // image preview
  const [selectedProfile, setSelectedProfile] = useState();
  return (
    <>
      <section className="common-top-style mybooking-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-md-2 col-12"></div>
            <div className="col-xl-5 col-md-8 col-12">
              <h3 className="about-heading mb-2">Settings</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-2 col-12"></div>
            <div className="col-xl-5 col-md-8 col-12">
              <div className="setings-box-style">
                <form onSubmit={profileForm.handleSubmit}>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label custom-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      aria-describedby="emailHelp"
                      value={profileForm.values.name}
                      onChange={profileForm.handleChange}
                    />
                  </div>
                  {profileForm.errors.name ? (
                    <p className="distance-style">{profileForm.errors.name}</p>
                  ) : null}
                  <div className="mb-2">
                    <label htmlFor="email" className="form-label custom-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="email"
                      value={profileForm.values.email}
                      onChange={profileForm.handleChange}
                    />
                  </div>
                  {profileForm.errors.email ? (
                    <p className="distance-style">{profileForm.errors.email}</p>
                  ) : null}
                  <div className="mb-2">
                    <label htmlFor="mobile" className="form-label custom-label">
                      Mobile
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      aria-describedby="mobile"
                      value={profileForm.values.mobile}
                      onChange={profileForm.handleChange}
                    />
                  </div>
                  {profileForm.errors.mobile ? (
                    <p className="distance-style">
                      {profileForm.errors.mobile}
                    </p>
                  ) : null}
                  <div className="mb-2 mt-4">
                    <div className="row">
                      <div className="col-md-10 col-9">
                        <input
                          className="form-control"
                          type="file"
                          id="profile"
                          name="profile"
                          onChange={(e) => {
                            profileForm.setFieldValue("profile", e.currentTarget.files[0])
                            setSelectedProfile(e.currentTarget.files[0])
                          }
                          }
                          accept="image/*"
                        />
                      </div>
                      {(selectedProfile || profileForm.values.profileExistPath) && (
                        <div className="col-md-2 col-3 text-end">
                          <img src={(selectedProfile) ? (URL.createObjectURL(selectedProfile)) : (profileForm.values.profileExistPath)} className="upload-img-size" />
                        </div>
                      )}
                    </div>
                  </div>
                  {profileForm.errors.profile ? (
                    <p className="distance-style">
                      {profileForm.errors.profile}
                    </p>
                  ) : null}
                  <div className="mt-3">
                    <button type="submit" className="btn btn-primary check-btn">
                      Submit
                    </button>
                  </div>
                </form>
                <hr />
                <div className="row mt-2">
                  <div className="col-md-6 col-12">
                    <Link to={'/change-password'} className="changepassword-style">Change Password</Link>
                  </div>
                  <div className="col-md-6 col-12">
                    <Link to={'/faq'} className="changepassword-style">FAQ's</Link>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12 col-12">
                    <Link to={'/'} className="delete-profile">Delete Account Request</Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Settings;
