import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export const FromLocationSection = ({
  handlePlaceSelected,
  isFormValidated,
  fromLatLong,
  fromInput,
  setFromInput,
}) => {
  return (
    <>
      <div className="mb-2">
        <label htmlFor="shiftingForm" className="form-label custom-label">
          Shifting From
        </label>
        <GooglePlacesAutocomplete
          selectProps={{
            value: fromInput,
            onChange: (place) => {
              setFromInput(place);
              handlePlaceSelected(place, "from");
            },
            placeholder: "Location",
          }}
          autocompletionRequest={{
            componentRestrictions: { country: "MY" },
          }}
        />
        {isFormValidated && Object.keys(fromLatLong).length === 0 && (
          <p className="distance-style">Please choose the location</p>
        )}
      </div>
    </>
  );
};
