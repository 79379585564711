import { Link } from "react-router-dom"
import Uploadimg from "../img/profile-img1.png"

const Resetpassword = () => {
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-3 col-md-2 col-12"></div>
                        <div className="col-xl-5 col-md-8 col-12">
                            <h3 className="about-heading mb-2">Reset Password</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-md-2 col-12"></div>
                        <div className="col-xl-5 col-md-8 col-12">
                            <div className="setings-box-style">
                                <form>
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="form-label custom-label">Old Password</label>
                                        <input type="password" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="form-label custom-label">New Password</label>
                                        <input type="password" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="form-label custom-label">Confirm Password</label>
                                        <input type="password" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mt-3">
                                        <Link to={'/'} className="btn btn-primary check-btn">Submit</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Resetpassword