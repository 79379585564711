import React from 'react';
import Index from '../Component';
import About from '../Component/about';
import ContactUs from '../Component/contactus';
import Login from '../Component/Authentication/login';
import Register from '../Component/Authentication/register';
import HelpSupport from '../Component/helpSupport';
import Services from '../Component/services';
import MyBookingLists from '../Component/Customer/myBookingLists';
import Terms from '../Terms/terms';
import Privacy from '../Terms/privacy';
import Settings from '../Component/Customer/settings';
import ChangePassword from '../Component/Customer/changePassword';
import Resetpassword from '../Component/resetpassword';
import Faq from '../Component/faq';
import InitiateBooking from '../Component/Booking/initiatebooking';
import ConfirmBooking from '../Component/Booking/confirmbooking';
import BudgetMoving from '../Component/ServicesWeOffer/budgetmoving';
import PremimumMoving from '../Component/ServicesWeOffer/premium';
import DisposalMoving from '../Component/ServicesWeOffer/disposal';
import ManpowerMoving from '../Component/ServicesWeOffer/manpower';
import MybookingDeatils from '../Component/Customer/myBookingDetails';

const publicRoutes = [
  { path: '/', element: <Index /> },
  { path: '/help-support', element: <HelpSupport /> },
  { path: '/services', element: <Services /> },
  { path: '/about', element: <About /> },
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/terms', element: <Terms /> },
  { path: '/privacy', element: <Privacy /> },
  { path: '/resetpassword', element: <Resetpassword /> },
  { path: '/faq', element: <Faq /> },
  { path: '/budget', element: <BudgetMoving /> },
  { path: '/premimum', element: <PremimumMoving /> },
  { path: '/disposal', element: <DisposalMoving /> },
  { path: '/manpower', element: <ManpowerMoving /> }
]

const bookingRoutes = [
  { path: '/initiate-booking', element: <InitiateBooking /> },
  { path: '/confirm-booking', element: <ConfirmBooking /> }
]

const loginRoutes = [
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> }
]

const authRoutes = [
  { path: '/my-bookings', element: <MyBookingLists /> },
  { path: '/mybookingdetails/:enBookingId', element: <MybookingDeatils /> },
  { path: '/settings', element: <Settings /> },
  { path: '/change-password', element: <ChangePassword /> },
]

export { publicRoutes, bookingRoutes, loginRoutes, authRoutes }