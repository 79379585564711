const Terms = () => {
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h3 className="about-heading mb-2">TERMS & CONDITIONS</h3>
                            <p className="common-para-style">Last modified: 16 OCT 2023</p>
                            <p className="common-para-style">Please read these Terms and Conditions carefully before using the services offered by The Movers Online (M) Sdn Bhd (“The Movers Online“) or (“We”, “Us”, or “Our”), a company incorporated under the laws of Malaysia. These terms set forth the legally binding terms and conditions for your use of Our Services as contained in http://www.themovers.my/my (“Website“) (collectively with the site, referred to as “Services“).</p>
                            <h6 className="mt-1 mb-3">GENERAL TERMS</h6>
                            <p className="common-para-style">Any such purchasing terms and conditions shall apply only if We expressly confirm the same in writing. Acceptance of your delivered goods shall be deemed an acknowledgment of our terms and conditions. We do not ship for you any combustible materials, items that contain poisonous fumes, and any such items that are deemed dangerous and illegal in Malaysia. You will be liable if you fail, neglect, or refuse to inform us of the nature and condition of your items for shipment.
                            </p>
                            <p className="common-para-style">Please also see our Return and Refund.
                            </p>
                            <p className="common-para-style">FAQs - An Option available at The Movers Online gathering information on how the service works and how specific activities can be performed.</p>
                            <h6 className="mt-1 mb-3">User Agreement</h6>
                            <p className="common-para-style">This document describes the terms with which The Movers Online offers users access to our services. This agreement describes the terms and conditions applicable to your use of our services available under The Movers Online domain.
                                USE OF THE INTERNET AS A MEDIUM OF COMMUNICATION
                                You acknowledge that the internet is not a secure medium and information submitted for the Services hosted on Our Website may be accessed by third parties. The Movers Online accepts no liability whatsoever in this circumstance.</p>
                            <h6 className="mt-1 mb-3">DOCUMENTATION OF OUR SERVICES</h6>
                            <p className="common-para-style">Any form of documentation provided by Us must not be copied or made available to any third party or used for any purpose other than the intended and agreed purpose as indicated on Our Website.</p>

                            <h6 className="mt-1 mb-3">DELIVERY OF GOODS</h6>
                            <p className="common-para-style">We endeavor to adhere to stipulated delivery deadlines as indicated on Our Website. However, due to the hazards and peculiar features of Our Services, delivery deadlines will not be binding unless expressly agreed otherwise. Our contractual obligations are subject to a case-to-case basis based on your request and additional charges may incur.
                            </p>
                            <h6 className="mt-1 mb-3">LIMITATION</h6>
                            <h6 className="mt-1 mb-3">IMPRACTICAL OPERATIONS</h6>
                            <p className="common-para-style">Nothing in these terms and conditions shall require the mover to perform pick-up or delivery service at any location from or to which it is impracticable, through no fault or neglect of the mover to operate vehicles because of:
                                The condition of roads, streets, driveways, or alleys.
                                Inadequate loading or unloading facilities; or
                                Riots, Acts of God, the public enemy, the authority of law, strikes or labor unrest the existence of violence, or such possible disturbances as to create a reasonable apprehension of danger to person or property.</p>
                            <h6 className="mt-1 mb-3">TRANSPORT AND GOODS LIMITATION</h6>
                            <p className="common-para-style">There are several prohibitions for the use of The Movers Online services. The following cases are specifically prohibited: ABSOLUTELY NO CARRIAGE OF ANY PERSON or ANIMALS in the cargo compartment for any distance or reason ever.
                                Items of extraordinary value or items that are irreplaceable should not be moved via The Movers Online.
                                NO Hazardous Materials to be carried but are not limited to explosives, gases, flammable liquids, flammable solids, poisonous or infectious substances, radioactive material, or corrosives.
                                NO firearms, ammunition, or other explosive materials.
                                NO illegal goods.
                                The Mover Online does not hold out to transport jewelry, objects d’art, currency, documents items of unusual value, or rare metals. Unless otherwise indicated herein or agreed to by contract.
                                Mover shall not be required to accept for transportation any truckload shipment which exceeds the gazette by Land Public Transport Commission (SPAD) and Road Transport Department (JPJ) weight limit or which occupies more than the full visible capacity of the lorry which is provided or size of the lorry that has been booked prior to pick up date even if the lorry provided may be able to carry the excess load.</p>
                            <h6 className="mt-1 mb-3">APPOINTMENTS/PICKUP AND DELIVERY TIMES
                            </h6>
                            <p className="common-para-style">Pick-ups and deliveries shall be made between 0800 and 0000 hrs local time unless special arrangements have already been made and agreed upon for timing beyond the previously mentioned time. Appointments shall be made at no charge. The mover shall not be liable for late deliveries or unkept appointments due to circumstances beyond the mover’s control such as but not limited to weather and traffic conditions, store loading and wait times, customer special requests, and other unexpected barriers to loading or unloading.</p>
                            <h6 className="mt-1 mb-3">LIMITATIONS OF MOVER’S LIABILITY FOR PREMISE CLEARANCE</h6>
                            <p className="common-para-style">
                                The Mover Online assumes no responsibility for insuring or otherwise providing for clearance of lorry through or inspection by premise’s management. The Mover Online is not responsible for the application for entry permit into pick up or delivery premise if the management rule and regulation require entry pass or permit to be applied beforehand. The Mover Online or party in possession shall not be liable for loss, damage, deterioration of the goods or delay in delivery due to the process for management clearance or inspection.</p>
                            <h6 className="mt-1 mb-3">PICKUP AND DELIVERY SERVICE</h6>
                            <p className="common-para-style">The rates named herein include pick up or delivery at all points within the limits of the cities, towns, villages, and other points from and to which rates apply, but each booking will include only pick up and one delivery unless special requests are made, and the additional charges are agreed upon.</p>
                            <h6 className="mt-1 mb-3">WAITING TIME</h6>
                            <p className="common-para-style">The Mover Online Vendor’s shall allow 30minutes of waiting time both for loading and unloading to begin. Such time shall commence from when the mover arrived or when the mover was requested to wait for loading or unloading to start, whichever is later. Late Charges is Applicable If there is needs to wait more than 30minutes – MYR50 / Each 30 Minutes</p>
                            <h6 className="mt-1 mb-3">APPLICATION OF ACCESSORIAL CHARGES</h6>
                            <p className="common-para-style">In addition to the base rate and additional services requested for any booking and unless otherwise agreed in writing, the following accessorial charges shall apply and shall be reflected on the amended booking details and receipt should the customer request for one.</p>
                            <h6 className="mt-1 mb-3">DETENTION</h6>
                            <p className="common-para-style">This item applies when the mover’s vehicles with driver are delayed or detained beyond the free time provided for herein at the time of delivery to the customer or at the time of pick-up when such delay is not the fault of the mover.
                                Unless otherwise agreed, charges for detention will be charged to the customer for both unloading and loading.
                                When computing time, the beginning time shall be the time the driver notifies the customer of the driver’s arrival and that the vehicle is available for loading or unloading but in no case shall time commence prior to the time of any appointment or the actual time of loading or unloading, whichever comes first.
                                When computing detention charges, all non-working time shall be excluded. Non-working time includes lunch breaks, coffee breaks, and rest breaks.
                                If, at the end of the business day, unloading has not been completed and cannot be completed that day, the customer shall be given the following options:
                                The mover may return to the mover’s terminal with what goods and items have not been unloaded, but the mover shall return the following day with the balance of the goods and items during the customer’s available time.
                                Any unused free time from the first day will continue into the second day, with charges to commence when all free time has expired.
                                If a vehicle is both unloaded and reloaded, each transaction will be considered separately, and free time shall apply to each separately.
                                When a delay occurs beyond free time, the charge for detention shall be RM50.00 per hour or fractions thereof.
                                Mover shall give the customer the opportunity to sign the detention records and the customer to make any corrections to these records at the time. If the customer refuses to sign said records, Mover’s records will govern.</p>
                            <h6 className="mt-1 mb-3">CANCELLATION OF BOOKING</h6>
                            <p className="common-para-style">Any cancellation shall be made BEFORE twenty-four (48) hours of scheduled pick-up by informing The Movers Online (M) Sdn. Bhd. Customer Service or the assigned mover by call, Whatsapp or SMS.
                                If a service is booked and then canceled within twenty-four (48) hours of scheduled pick-up, the customer shall pay 50% of the booking total price or RM150 cancellation charge (whichever is lower). Cancellation fee may also be applicable but not limited to the following circumstances:
                                If the mover already arrived and discovers, through communication with the customer, that the customer’s items are not eligible for the Services due to not limited to, items under the “TRANSPORT AND GOODS LIMITATIONS” list and/or incorrect information provided by the customer that makes the moving process unable to proceed.</p>

                            <h6 className="mt-1 mb-3">CASH ON DELIVERY</h6>
                            <p className="common-para-style">For the Cash-on-delivery payment method, the mover will accept cash payment only when the customer chooses Cash-on-delivery (CoD) as a method of payment when completing the booking form. For payment methods other than cash such as and not limited to cheque and bank transfer, the payment shall be made to The Movers Online (M) Sdn Bhd, not to the mover unless agreed upon. For “Online Payment” method, the mover shall not be allowed to request additional payment from the customer unless there is an additional request that is not included in the original booking and the additional charges that are agreed upon shall be made to the mover. Mover’s drivers are not authorized to produce any invoice or receipt for the service provided. The Movers Online (M) Sdn Bhd shall provide an invoice or receipt upon the customer’s request. If a collect-on-delivery shipment is inadvertently accepted and delivered without obtaining payment or if payment is stopped on uncertified funds, the customer is responsible for ensuring full payment is made within three (3) days. The mover will collect charges from the delivery receiver ONLY when requested by the customer.
                            </p>
                            <h6 className="mt-1 mb-3">ADDITIONAL LICENSE, PERMITS, AND DEPOSIT FEES</h6>
                            <p className="common-para-style">Whenever any additional license or permits are required, such as but not limited to, warehouse entrance pass, foreign commerce, customs fee and premise’s management deposit fee, the charge shall be arranged and provided by the customer and not the mover.
                                If and/or when mover(s) are refused entry due to improper documentation, the fee of 50% from the booking total price or RM200 will be assessed accordingly. Additional fees for driver detention, and/or storage will also be applicable.</p>

                            <h6 className="mt-1 mb-3">LONG PUSH</h6>
                            <p className="common-para-style">The Movers Online reserves the right to charge a fee for a ‘long push’ service. A base fare will be charged when movers load and unload your belongings from a minimum distance of 50 meters away from the unloading area to the building entrance or loading bay.
                            </p>
                            <h6 className="mt-1 mb-3">STAIR CARRY</h6>
                            <p className="common-para-style">The Movers Online reserves the right to charge a fee for ‘stair carry’ service, a base fare will be charged when movers are required to load and unload your belongings using stairs. This applies to a building that an elevator or service lift is not accessible for moving.
                            </p>
                            <h6 className="mt-1 mb-3">MANPOWER ONLY SERVICE</h6>
                            <p className="common-para-style">Manpower-only service is only available upon request and is limited to Klang Valley area only. The service is also subject to availability and an additional charge of a minimum RM50.00 for manpower transportation charge. The manpower charge will be a minimum of RM100 per person and the manpower working hours will be from 9AM – 5PM and include break hours. Any request for additional service such as and not limited to, furniture assembly, packing, and wrapping shall follow our standard rate published on the website.</p>
                            <h6 className="mt-1 mb-3">TAILGATE UNITS
                            </h6>
                            <p className="common-para-style">A booking fee of RM100.00 is applicable when requesting a lorry equipped with a hydraulic tailgate.</p>
                            <p className="common-para-style"> STORAGE OF LOADS AT MOVER’S FACILITY</p>
                            <p className="common-para-style">The mover will assess RM100.00 per night applicable for all loads stored at the mover’s facility.</p>

                            <h6 className="mt-1 mb-3">FINES
                            </h6>
                            <p className="common-para-style">Customer shall be the responsible party for payment of or reimbursement to the mover, for any fines resulting from excess weight or dimension of any shipment that causes the mover to be in violation of any local, state, or federal law. Fines incurred for equipment defects are the responsibility of the equipment owner.</p>
                            <h6 className="mt-1 mb-3">EXPRESS BOOKING
                            </h6>
                            <p className="common-para-style">Mover will make pickups and deliveries on the same day/next day the booking was made (Express booking) for an additional charge calculated based on the lorry trip price. In addition, detention as otherwise provided herein shall apply between the time of arrival and loading or unloading and/or the time of subsequent dispatch.</p>
                            <h6 className="mt-1 mb-3">RE-CONSIGNMENT OR DIVERSION
                            </h6>
                            <p className="common-para-style">A request for diversion of transport will be subject to the following definitions, conditions, and charges:
                                Request for diversion must be informed to The Movers Online (M) Sdn. Bhd. Customer Service department in writing (e-mail, Whatsapp or SMS) or phone call.
                                Diversion prior to the movement of a shipment shall bear the rate that would otherwise have been applied if the shipment was originally scheduled for movement to the destination.
                                Diversion en route shall be determined based on the distance from the origin to the final destination via the diversion point.
                                If the transport is returned to the origin point, the rate to be applied will be the applicable rate to the most distant point traveled in addition to the mileage rate from the same back to point of origin.</p>
                            <h6 className="mt-1 mb-3">ADDITIONAL PICK UP/DROP OFF (STOP-OFF)
                            </h6>
                            <p className="common-para-style">The following provisions govern additional stops in transit to partially load or unload, except as otherwise specifically provided.
                                Items received from one receiver at one point at one time in one booking, may be stopped in transit for partial loading and/or unloading only at points within the scope of mover’s operations or as otherwise agreed by mover.
                                The party or parties authorized and designated by the customer to accept at a point or place of stop-off may be the same or other than the customer in the booking details.
                                The request for additional pick up or drop off must designate the following:
                                Stop-off point or points and places.
                                The weight, quantities, markings, and description of articles to be loaded or unloaded.
                                The name, contact number, and address of the party authorized to accept an item for unloading at the point of stop-off.
                                A notation will be made on the booking details and description of the portion of the items unloaded at each stop-off.
                                The substitution of items for that originally loaded or any exchange of contents at a point or place of stop-off may be permitted if agreed to with the customer and noted on booking details.
                                Additional charges on item(s) stopped to partially load or unload must be prepaid or guaranteed by the customer. If not prepaid, the customer must show on the booking details the name of one party from whom the entire charges, including the stop-off charges, shall be collected, which must be a party to whom a portion of the items to be delivered.
                                The rate that the customer shall pay for the mover’s stop-off service shall be assessed at the rate applicable from the point of origin to the stop-off location and/or from one stop-off location to another stop-off location in addition to the original charge for the point-of-origin to the final destination.</p>

                            <h6 className="mt-1 mb-3">PASSING OF RISK
                            </h6>
                            <p className="common-para-style">When your goods are transported or moved for the purpose of transportation, any risk (including accidental loss, destruction, or deterioration) (“Risk“) shall pass to you as soon as we have delivered the goods to the assigned mover. As part of Our value-added Services, We will assist in ensuring the highest level of care is administered while handling and transporting your goods but we cannot accept any liability arising therefrom.</p>
                            <h6 className="mt-1 mb-3">INDEMNITY AND LIMITATION OF LIABILITY
                            </h6>
                            <p className="common-para-style">You undertake the Services at your own risk and agree to indemnify The Movers Online and its employees against all costs, losses, damages, expenses, and liabilities (including for loss of reputation and goodwill and professional advisors fees) and any claim arising from your own actions in any way in connection with the Services, or a breach of your obligations as set forth in these Terms and Conditions.
                                The Movers Online is not liable in any way for any costs, expenses, damages, liability, or injury arising out of or in any way connected with the Services. This limitation does not exclude any liability for negligence by The Movers Online or death or personal injury arising out of such negligence.</p>

                            <h6 className="mt-1 mb-3">SEVERABILITY
                            </h6>
                            <p className="common-para-style">The invalidity, illegality, or unenforceability of the whole or part of these Terms and Conditions do not affect or impair the continuation in force of the remainder of the Terms and Conditions.
                            </p>
                            <h6 className="mt-1 mb-3">ENTIRE AGREEMENT
                            </h6>
                            <p className="common-para-style">These Terms and Conditions represent the entire agreement between the parties relating to the Services (unless otherwise expressly confirmed in writing by The Movers Online) and supersede all prior representations, agreements, negotiations or understandings (whether oral or in writing). Except as specifically set out herein, all conditions, warranties and representations expressed or implied by law are excluded. For the avoidance of doubt, no information of any nature about the Services or any of these Terms and Conditions should be relied upon unless confirmed in writing by The Movers Online.</p>
                            <h6 className="mt-1 mb-3">
                                NO WAIVER
                            </h6>
                            <p className="common-para-style">The failure to exercise or delay in exercising a right or remedy provided hereunder or by law does not constitute a waiver of the right or remedy or waiver of other rights or remedies.</p>
                            <h6 className="mt-1 mb-3">
                                DAMAGES AND LOSS
                            </h6>
                            <p className="common-para-style">Cash-out will be withheld if we receive complaints from customers regarding damage or loss to items and appropriate evidence is provided. Our partners are fully responsible for inspecting the condition of items before loading and ensuring they handle them with the utmost care.</p>
                            <h6 className="mt-1 mb-3">
                                REFUND POLICY
                            </h6>
                            <p className="common-para-style">If an order (booking) was not performed, all parties must abide by The Movers Online cancellation policy The cancellation process ensures the integrity of the support system and the accuracy of any refund</p>
                            <h6 className="mt-1 mb-3">
                                GOVERNING LAW
                            </h6>
                            <p className="common-para-style">These Terms and Conditions shall be governed by and interpreted in accordance with Malaysian law and the parties irrevocably submit to the exclusive jurisdiction of the Malaysian courts.</p>
                            <h6 className="mt-1 mb-3">
                                SPECIAL TERMS
                            </h6>
                            <p className="common-para-style">These Terms and Conditions are not intended to nor shall create any rights, entitlements, claims or benefits enforceable by any person that is not a party to them. No person shall derive any benefit or have any right, entitlement, or claim in relation to these Terms and Conditions.</p>
                            <h6 className="mt-1 mb-3">
                                CHANGES TO THE TERMS AND CONDITIONS
                            </h6>
                            <p className="common-para-style">We reserve the right to update these Terms and Conditions at any time and any changes may be made without notice to you. We recommend you read these Terms and Conditions periodically.
                                By continuing to access or use Our Services after those revisions or changes become effective, you agree to be bound by the revised terms. If you do not agree to the new Terms and Conditions, please stop using the Website and/or Our Services.</p>
                            <p className="common-para-style">
                                All of our rights are reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Terms