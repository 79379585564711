import React from 'react';
import './FullScreenLoader.css'; // Import your CSS styles (optional)

const FullScreenLoader = () => {
  return (
    <div className="full-screen-loader">
      <div className="loader-spinner"></div>
    </div>
  );
};

export default FullScreenLoader;