import { Link } from "react-router-dom"
import Googlepay from '../img/play-logo.png'
import AppleIcon from '../img/apple-down.png'
const Footer = () => {
    return (
        <>
            <footer className="position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Quick Links</h5>
                            <ul className="footer-links">
                                <li>
                                    <Link to={'/'}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/about'}>
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/my-bookings'}>
                                        My Bookings
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/terms'}>
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/privacy'}>
                                        Privacy Policy
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to={'/'}>
                                        Sitmap
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to={'/faq'}>
                                        FAQ's
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Download</h5>
                            <div className="mb-4">
                                <Link to={'https://play.google.com/store/apps/details?id=com.themovers.themovers_customer'}>
                                    <img src={Googlepay} className="footer-logos1" />
                                </Link>
                            </div>
                            <div className="">
                                <Link to={'https://apps.apple.com/my/app/themovers-moving-services/id6470928815'}>
                                    <img src={AppleIcon} className="footer-logos" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Social Links</h5>
                            <div className="d-flex">
                                <Link to={'https://www.facebook.com/themoversonIine'} className="social-bg-style">
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </Link>
                                {/* <Link to={'/'} className="social-bg-style">
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </Link> */}
                                <Link to={'https://www.instagram.com/themoversonline?igsh=MWw1dmJoYXZoeWNxMw%3D%3D'} className="social-bg-style">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </Link>
                                {/* <Link to={'/'} className="social-bg-style">
                                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Address</h5>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-map-marker marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">No:2 , Jalan Puj 9/23 ,
                                        Taman Puncak Jalil , 43300 Seri Kembangan.</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-envelope-o marker-size1" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">info@themovers.my</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle"> +60123244261</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="https://wa.me/+60123244261" target="_blank" className="whatsup-icon-bottom-style">
                        <i class="fa fa-whatsapp whatsup-size" aria-hidden="true"></i>
                    </a>
                </div>
            </footer>
        </>
    )
}

export default Footer