import React, { useState, useEffect, useCallback } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { GoogleMapSection } from "./googleMapSection";
import { Link, useNavigate } from "react-router-dom";
import { DateTimeSection } from "./dateTimeSection";
import { FromLocationSection } from "./fromLocationSection";
import { ToLocationSection } from "./toLocationSection";
import { bookingInitiated } from "../../Reduxstore/bookingSlice";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { manageLoader } from "../../Reduxstore/loaderSlice";

export const BookingSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fromPlace, setFromPlace] = useState(null);
  const [toPlace, setToPlace] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [fromLatLong, setFromLatLong] = useState({});
  const [toLatLong, setToLatLong] = useState({});
  const [center, setCenter] = useState({ lat: 4.2105, lng: 101.9758 });
  const [pickupDate, setPickupDate] = useState(null);
  const [pickupTime, setPickupTime] = useState(null);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [fromInput, setFromInput] = useState(null);
  const [toInput, setToInput] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ["places"],
  });

  const fetchPlaceDetails = (placeId, callback) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        callback(place);
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  const calculateDistance = useCallback(() => {
    if (fromPlace && toPlace && isLoaded) {
      if (fromPlace?.geometry && toPlace?.geometry) {
        const origin = new window.google.maps.LatLng(
          Number(fromPlace?.geometry?.location?.lat()),
          Number(fromPlace?.geometry?.location?.lng())
        );
        const destination = new window.google.maps.LatLng(
          Number(toPlace?.geometry?.location?.lat()),
          Number(toPlace?.geometry?.location?.lng())
        );
        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status === "OK") {
              const distanceValue =
                response?.rows[0]?.elements[0]?.distance?.value;
              const distanceText =
                response?.rows[0]?.elements[0]?.distance?.text;
              const durationText =
                response?.rows[0]?.elements[0]?.duration?.text;
              setDistance({ value: distanceValue, text: distanceText });
              setDuration(durationText);
            } else {
              console.error("Error calculating distance:", status);
              setDistance(null);
              setDuration(null);
            }
          }
        );
      } else {
        console.error("Missing geometry data for fromPlace or toPlace");
      }
    }
  }, [fromPlace, toPlace, isLoaded]);
  useEffect(() => {
    calculateDistance();
  }, [fromPlace, toPlace, calculateDistance]);
  const getLatLngLiteral = (geometry) => ({
    lat: Number(geometry?.location?.lat()),
    lng: Number(geometry?.location?.lng()),
  });
  const handlePlaceSelected = (place, field) => {
    fetchPlaceDetails(place?.value?.place_id, (placeDetails) => {
      if (field === "from") {
        setFromPlace(placeDetails);
        if (placeDetails?.geometry) {
          const position = getLatLngLiteral(placeDetails?.geometry);
          if (position?.lat && position?.lng) {
            setCenter(position);
            setFromLatLong(position);
          }
        }
      } else {
        setToPlace(placeDetails);
        if (placeDetails?.geometry) {
          const position = getLatLngLiteral(placeDetails?.geometry);
          if (position?.lat && position?.lng) {
            setCenter(position);
            setToLatLong(position);
          }
        }
      }
    });
  };
  // booking
  const [bookingType, setBookingType] = useState("budget");

  const handleBookingType = (type) => setBookingType(type);
  useEffect(() => {
    clearBookingData();
  }, [bookingType]);

  const clearBookingData = () => {
    setFromPlace(null);
    setToPlace(null);
    setDistance(null);
    setDuration(null);
    setFromLatLong({});
    setToLatLong({});
    setFromInput(null);
    setToInput(null);
  };

  const initiateBoookingForm = async (bookingData) => {
    let bookingInitiatedResponse = await dispatch(bookingInitiated(bookingData));
    if (bookingInitiatedResponse) {
      navigate('/initiate-booking')
    } else {
      toast.error('Invalid booking details!')
    }
  }

  const validateBookingForm = async () => {
    setIsFormValidated(true);
    let bookingData = {
      bookingType: bookingType,
      fromPlace: fromPlace?.formatted_address ? fromPlace?.formatted_address : null,
      toPlace: toPlace?.formatted_address ? toPlace?.formatted_address : null,
      fromLatLong: fromLatLong,
      toLatLong: toLatLong,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      distance: distance,
      duration: duration,
    };
    if (bookingType === "budget" || bookingType === "premium") {
      console.log('1-sec',bookingType)
      if (Object.keys(fromPlace).length > 0 && Object.keys(toPlace).length > 0 && Object.keys(fromLatLong).length > 0 && Object.keys(toLatLong).length > 0 && pickupDate && pickupTime && distance && duration) {
        await initiateBoookingForm(bookingData)
      } else {
        toast.error('Invalid booking details!')
      }
    } else if (bookingType === "disposal" || bookingType === "manpower") {
      console.log('2-sec',bookingType)
      if (Object.keys(fromPlace).length > 0 && Object.keys(fromLatLong).length > 0 && pickupDate && pickupTime) {
        await initiateBoookingForm(bookingData)
      } else {
        toast.error('Invalid booking details!')
      }
    }
  };
  if (!isLoaded) return <div></div>;
  return (
    <>
      <div className="contactform-section">
        <h6>Where are you going to relocate?</h6>
        <ul
          className="nav nav-pills mb-3 mt-3 custom-pills"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link custom-link active"
              id="pills-budget-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-budget"
              type="button"
              role="tab"
              aria-controls="pills-budget"
              aria-selected="true"
              onClick={() => handleBookingType("budget")}
            >
              Budget <br />
              Moving
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link custom-link"
              id="pills-premium-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-premium"
              type="button"
              role="tab"
              aria-controls="pills-premium"
              aria-selected="false"
              onClick={() => handleBookingType("premium")}
            >
              Premium
              <br /> Moving
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link custom-link"
              id="pills-disposal-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-disposal"
              type="button"
              role="tab"
              aria-controls="pills-disposal"
              aria-selected="false"
              onClick={() => handleBookingType("disposal")}
            >
              Disposal <br />
              Service
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link custom-link"
              id="pills-manpower-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-manpower"
              type="button"
              role="tab"
              aria-controls="pills-manpower"
              aria-selected="false"
              onClick={() => handleBookingType("manpower")}
            >
              Manpower
              <br /> Service
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-budget"
            role="tabpanel"
            aria-labelledby="pills-budget-tab"
            tabIndex="0"
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <DateTimeSection
                  pickupDate={pickupDate}
                  setPickupDate={setPickupDate}
                  pickupTime={pickupTime}
                  setPickupTime={setPickupTime}
                  isFormValidated={isFormValidated}
                />
                <FromLocationSection
                  fromInput={fromInput}
                  setFromInput={setFromInput}
                  handlePlaceSelected={handlePlaceSelected}
                  isFormValidated={isFormValidated}
                  fromLatLong={fromLatLong}
                />
                <ToLocationSection
                  toInput={toInput}
                  setToInput={setToInput}
                  handlePlaceSelected={handlePlaceSelected}
                  isFormValidated={isFormValidated}
                  toLatLong={toLatLong}
                />
                <div className="mt-2 mb-3">
                  <GoogleMapSection
                    center={center}
                    fromLatLong={fromLatLong}
                    toLatLong={toLatLong}
                    distance={distance}
                    duration={duration}
                  />
                </div>
                <div className="">
                  <Link
                    to="#"
                    className="btn btn-primary check-btn"
                    onClick={validateBookingForm}
                  >
                    Check Prices
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-premium"
            role="tabpanel"
            aria-labelledby="pills-premium-tab"
            tabIndex="0"
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <DateTimeSection
                  pickupDate={pickupDate}
                  setPickupDate={setPickupDate}
                  pickupTime={pickupTime}
                  setPickupTime={setPickupTime}
                  isFormValidated={isFormValidated}
                />
                <FromLocationSection
                  fromInput={fromInput}
                  setFromInput={setFromInput}
                  handlePlaceSelected={handlePlaceSelected}
                  isFormValidated={isFormValidated}
                  fromLatLong={fromLatLong}
                />
                <ToLocationSection
                  toInput={toInput}
                  setToInput={setToInput}
                  handlePlaceSelected={handlePlaceSelected}
                  isFormValidated={isFormValidated}
                  toLatLong={toLatLong}
                />
                <div className="mt-2 mb-3">
                  <GoogleMapSection
                    center={center}
                    fromLatLong={fromLatLong}
                    toLatLong={toLatLong}
                    distance={distance}
                    duration={duration}
                  />
                </div>
                <div className="">
                  <Link
                    to="#"
                    className="btn btn-primary check-btn"
                    onClick={validateBookingForm}
                  >
                    Check Prices
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-disposal"
            role="tabpanel"
            aria-labelledby="pills-disposal-tab"
            tabIndex="0"
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <DateTimeSection
                  pickupDate={pickupDate}
                  setPickupDate={setPickupDate}
                  pickupTime={pickupTime}
                  setPickupTime={setPickupTime}
                  isFormValidated={isFormValidated}
                />
                <FromLocationSection
                  fromInput={fromInput}
                  setFromInput={setFromInput}
                  handlePlaceSelected={handlePlaceSelected}
                  isFormValidated={isFormValidated}
                  fromLatLong={fromLatLong}
                />
                <div className="mt-2 mb-3">
                  <GoogleMapSection
                    center={center}
                    fromLatLong={fromLatLong}
                    toLatLong={toLatLong}
                    distance={distance}
                    duration={duration}
                  />
                </div>
                <div className="">
                  <Link
                    to="#"
                    className="btn btn-primary check-btn"
                    onClick={validateBookingForm}
                  >
                    Check Prices
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-manpower"
            role="tabpanel"
            aria-labelledby="pills-manpower-tab"
            tabIndex="0"
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <DateTimeSection
                  pickupDate={pickupDate}
                  setPickupDate={setPickupDate}
                  pickupTime={pickupTime}
                  setPickupTime={setPickupTime}
                  isFormValidated={isFormValidated}
                />
                <FromLocationSection
                  fromInput={fromInput}
                  setFromInput={setFromInput}
                  handlePlaceSelected={handlePlaceSelected}
                  isFormValidated={isFormValidated}
                  fromLatLong={fromLatLong}
                />
                <div className="mt-2 mb-3">
                  <GoogleMapSection
                    center={center}
                    fromLatLong={fromLatLong}
                    toLatLong={toLatLong}
                    distance={distance}
                    duration={duration}
                  />
                </div>
                <div className="">
                  <Link
                    to="#"
                    className="btn btn-primary check-btn"
                    onClick={validateBookingForm}
                  >
                    Check Prices
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
