import QuestionsLists from "./questionsLists"

const Faq = () => {
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 faq-padding">
                            <QuestionsLists />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Faq