import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isBookingInitiated: false,
  initialBookingDetails: {},
  previewBookingDetails: {},
  inCompleteBookingDetails: {}
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    bookingInitiated: (state, { payload }) => {
      state.isBookingInitiated = true;
      state.initialBookingDetails = payload;
      state.previewBookingDetails = {};
      state.inCompleteBookingDetails = {};
    },
    bookingPreviewed: (state, { payload }) => {
      state.isBookingInitiated = state.isBookingInitiated;
      state.initialBookingDetails = state.initialBookingDetails;
      state.previewBookingDetails = payload;
      state.inCompleteBookingDetails = {};
    },
    bookingIncompleted: (state, { payload }) => {
      state.isBookingInitiated = true;
      state.initialBookingDetails = state.initialBookingDetails;
      state.previewBookingDetails = state.previewBookingDetails;
      state.inCompleteBookingDetails = payload;
    },
    bookingSubmitted: (state, { payload }) => {
      state.isBookingInitiated = false;
      state.initialBookingDetails = {};
      state.previewBookingDetails = {};
      state.inCompleteBookingDetails = {};
    }
  },
  extraReducers: (builder) => {
  }
});

export const { bookingInitiated, bookingPreviewed, bookingIncompleted, bookingSubmitted } = bookingSlice.actions;
export default bookingSlice.reducer;
