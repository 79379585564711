import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export const ToLocationSection = ({
  handlePlaceSelected,
  isFormValidated,
  toLatLong,
  toInput,
  setToInput = { setToInput },
}) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="shiftingTo" className="form-label custom-label">
          Shifiting To
        </label>
        <GooglePlacesAutocomplete
          selectProps={{
            value: toInput,
            onChange: (place) => {
              setToInput(place);
              handlePlaceSelected(place, "to");
            },
            placeholder: "Location",
          }}
          autocompletionRequest={{
            componentRestrictions: { country: "MY" },
          }}
        />
        {isFormValidated && Object.keys(toLatLong).length === 0 && (
          <p className="distance-style">Please choose the location</p>
        )}
      </div>
    </>
  );
};
