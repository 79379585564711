import Headerlogo from '../img/registerlogo.png'
import Profile from '../img/profile-img1.png'
import { Link } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import { setAuthLogoutTrue,setAuthLogoutFalse } from "../Reduxstore/authSlice";
import { toast } from 'react-toastify';

const Header = () => {
    let isAuthenticated = useSelector(state => state.auth)
    let isBooked = useSelector(state => state.booking)
    const dispatch = useDispatch()
    const handleLogout = async () => {
        dispatch(setAuthLogoutTrue())
        setTimeout(() => {
            dispatch(setAuthLogoutFalse())
        },1000)
    }
    return (
        <>
            <header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 col-6 align-self-center">
                            <Link to='/'><img src={Headerlogo} className='mainheader-logo' /></Link>
                        </div>
                        <div className="col-md-9 text-end align-self-center d-none d-md-none d-xl-block">
                            <ul className="header-list">
                                <li>
                                    <Link to="/">
                                        <i className="fa fa-home me-1" aria-hidden="true"></i>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={(isAuthenticated.isLogin) ? ("/my-bookings") : ("/login")}>
                                        <i className="fa fa-calendar-check-o me-1" aria-hidden="true"></i>
                                        My Bookings
                                    </Link>
                                </li>
                                {(!isAuthenticated.isLogin) && (
                                    <>
                                        <li>
                                            <Link to="/register">
                                                <i className="fa fa-user-plus me-1" aria-hidden="true"></i>
                                                Sign Up
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/login">
                                                <i className="fa fa-sign-in me-1" aria-hidden="true"></i>
                                                Sign In
                                            </Link>
                                        </li>
                                    </>
                                )}
                                {(isAuthenticated.isLogin) && (
                                    <>
                                        <li onClick={handleLogout}>
                                            <Link to="#">
                                                <i className="fa fa-sign-in me-1" aria-hidden="true"></i>
                                                Logout
                                            </Link>
                                        </li>
                                    </>
                                )}
                                {/* <li>
                                        <span className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle profile-style" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fa fa-user-circle-o me-1" aria-hidden="true"></i>
                                                Username
                                            </button>
                                            <ul className="dropdown-menu list-header" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="#">Name</a></li>
                                                <li><a className="dropdown-item" href="#">My Profile</a></li>
                                                <li><a className="dropdown-item" href="#">Log Out</a></li>
                                            </ul>
                                        </span>
                                    </li> */}
                                <li>
                                    <button className="btn btn-primary nav-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling"> <i className="fa fa-bars" aria-hidden="true"></i></button>

                                    <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="true" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                                        <div className='canwas-style-top'>
                                            <div className="offcanvas-header1">
                                                <div className='row d-flex align-items-center'>
                                                    <div className='col-md-2'>
                                                        <a href='#' data-bs-dismiss="offcanvas" aria-label="Close" className='custom-menu'><i className="fa fa-times" aria-hidden="true"></i></a>
                                                        {/* <button type="button" className="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                                                    </div>
                                                    <div className='col-md-10 text-end'>
                                                        <h4 className='profile-name'>
                                                            <img src={(isAuthenticated?.postItems?.Customer?.profile_image) ? (`https://app.themovers.com.my/public/customer_uploads/${isAuthenticated?.postItems?.Customer?.profile_image}`) : Profile} className='profile-size me-2' />
                                                            {isAuthenticated?.postItems?.Customer?.name}
                                                        </h4>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="offcanvas-body custom-off-style">
                                            <ul className='left-side-menu'>
                                                <li><Link to={'/'} data-bs-dismiss="offcanvas"><i className="fa fa-home me-1" aria-hidden="true"></i> Home</Link></li>
                                                <li><Link to={'/my-bookings'} data-bs-dismiss="offcanvas"><i className="fa fa-calendar-check-o me-1" aria-hidden="true"></i> Bookings</Link></li>
                                                <li><Link to={'/settings'} data-bs-dismiss="offcanvas"><i className="fa fa-address-book-o me-1" aria-hidden="true"></i> My Profile</Link></li>
                                                {/* <li><Link to={'/'}><i className="fa fa-info-circle me-1" aria-hidden="true"></i> Help and Support</Link></li> */}
                                                <li><Link to={'/services'} data-bs-dismiss="offcanvas"><i className="fa fa-server me-1" aria-hidden="true"></i> Services</Link></li>
                                                <li><Link to={'/about'} data-bs-dismiss="offcanvas"><i className="fa fa-suitcase me-2" aria-hidden="true"></i>
                                                    About Us</Link></li>
                                                <li><Link to={'/contact-us'} data-bs-dismiss="offcanvas"><i className="fa fa-volume-control-phone me-2" aria-hidden="true"></i>Contact Us</Link></li>
                                                {(!isAuthenticated.isLogin) && (
                                                    <li><Link to={'/login'} data-bs-dismiss="offcanvas"><i className="fa fa-sign-in me-2" aria-hidden="true"></i>Log In</Link></li>
                                                )}
                                                 {(isAuthenticated.isLogin) && (
                                                   <li><Link to="#" data-bs-dismiss="offcanvas" onClick={handleLogout}><i className="fa fa-sign-in me-2" aria-hidden="true"></i>Log Out</Link></li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-9 col-6 text-end align-self-center d-block d-md-block d-xl-none">
                            <button className="btn btn-primary nav-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrollingMobile" aria-controls="offcanvasScrollingMobile"> <i className="fa fa-bars" aria-hidden="true"></i></button>
                            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="true" tabIndex="-1" id="offcanvasScrollingMobile" aria-labelledby="offcanvasScrollingLabelMobile">
                                <div className="offcanvas-header1">
                                    <div className='row d-flex align-items-center'>
                                        <div className='col-2'>
                                            <a href='#' data-bs-dismiss="offcanvas" aria-label="Close" className='custom-menu'><i className="fa fa-times" aria-hidden="true"></i></a>
                                        </div>
                                        <div className='col-10 text-end'>
                                            <h4 className='profile-name'>
                                                <img src={(isAuthenticated?.postItems?.Customer?.profile_image) ? (`https://app.themovers.com.my/public/customer_uploads/${isAuthenticated?.postItems?.Customer?.profile_image}`) : Profile} className='profile-size me-2' />
                                                {isAuthenticated?.postItems?.Customer?.name}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="offcanvas-body custom-off-style">
                                    <ul className='left-side-menu'>
                                        <li><Link to={'/'} data-bs-dismiss="offcanvas"><i className="fa fa-home me-1" aria-hidden="true"></i> Home</Link></li>
                                        <li><Link to={'/my-bookings'} data-bs-dismiss="offcanvas"><i className="fa fa-calendar-check-o me-1" aria-hidden="true"></i> Bookings</Link></li>
                                        <li><Link to={'/settings'} data-bs-dismiss="offcanvas"><i className="fa fa-address-book-o me-1" aria-hidden="true"></i> My Profile</Link></li>
                                        {/* <li><Link to={'/'}><i className="fa fa-info-circle me-1" aria-hidden="true"></i> Help and Support</Link></li> */}
                                        <li><Link to={'/services'} data-bs-dismiss="offcanvas"><i className="fa fa-server me-1" aria-hidden="true"></i> Services</Link></li>
                                        <li><Link to={'/about'} data-bs-dismiss="offcanvas"><i className="fa fa-suitcase me-2" aria-hidden="true"></i>
                                            About Us</Link></li>
                                        <li><Link to={'/contact-us'} data-bs-dismiss="offcanvas"><i className="fa fa-volume-control-phone me-2" aria-hidden="true"></i>Contact Us</Link></li>
                                        {(!isAuthenticated.isLogin) && (
                                            <>
                                            <li><Link to={'/login'} data-bs-dismiss="offcanvas"><i className="fa fa-sign-in me-2" aria-hidden="true"></i>Log In</Link></li>
                                            <li><Link to={'/register'} data-bs-dismiss="offcanvas"><i className="fa fa fa-user-plus me-2" aria-hidden="true"></i>Sign Up</Link></li>
                                            </>
                                        )}
                                        {(isAuthenticated.isLogin) && (
                                        <li><Link to="#" onClick={handleLogout} data-bs-dismiss="offcanvas"><i className="fa fa-sign-in me-2" aria-hidden="true"></i>Log Out</Link></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header