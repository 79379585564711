import React, { useState, useEffect } from "react";
import { getAxios } from "../Utilities/commonAxios";

const QuestionsLists = () => {
  const [faqList, setFaqList] = useState([]);
  const getFaqListApi = async () => {
    await getAxios({
      url: `${process.env.REACT_APP_API_URL}/customer_faq_list`,
    }).then((response) => setFaqList(response?.data));
  };
  useEffect(() => {
    getFaqListApi();
  }, []);
  return (
    <>
      <div className="services-section-inner according-style">
        <h4 className="service-text">FAQ's</h4>
        <div className="accordion" id="accordionParent">
          {faqList.Api_success &&
            faqList.customer_faq_list.map((list, index) => (
              <>
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`'heading${index}'`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index}`}
                    >
                      {list.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionParent"
                  >
                    <div className="accordion-body">
                      <p className="according-pera">{list.answer}</p>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};
export default QuestionsLists;
