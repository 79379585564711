import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import loaderReducer from "./loaderSlice";
import bookingReducer from "./bookingSlice";

const secretKey = "m@v&r$"; 

const reducerList = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  booking: bookingReducer
});

const persistConfig = {
  key: "userSession",
  storage,
  transforms: [encryptTransform({ secretKey })],
};

const persistedReducer = persistReducer(persistConfig, reducerList);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

export const persistor = persistStore(store);