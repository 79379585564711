import { toast } from "react-toastify";
import { Navigate } from "react-router";
import { useSelector } from 'react-redux';

export const AuthMiddleware = (props) => {
  let isAuthenticated = useSelector(state => state.auth)
  if ((Object.keys(isAuthenticated?.postItems).length > 0 || isAuthenticated?.postItems.length > 0) && isAuthenticated.isLogin) {
    return (
      <>
        {props.children}
      </>
    )
  } else if ((Object.keys(isAuthenticated?.postItems).length === 0 && !isAuthenticated.isLogin && isAuthenticated.isLogout)) {
    toast.success('Logout successfully!');
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  } else if (!isAuthenticated.isLogin) {
    toast.error('Unauthorized access!');
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  } else {
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  }
}

export const BookingMiddleware = (props) => {
  let isBooked = useSelector(state => state.booking)
  let isAuthenticated = useSelector(state => state.auth)
  if(isAuthenticated.isLogout) {
    toast.success('Logout successfully!');
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  } else if (isBooked.isBookingInitiated) {
    return (
      <>
        {props.children}
      </>
    )
  } else {
    toast.error('Unauthorized access!');
    return (<Navigate to={{ pathname: "/", state: { from: props.location } }} />)
  }
}