const Privacy = () => {
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h3 className="about-heading mb-3 text-center">Privacy Policies</h3>
                            <h3 className="about-heading mb-2">INTRODUCTION</h3>
                            <p className="common-para-style mb-1">Privacy Policy for The Movers Online (M) Sdn Bhd</p>
                            <p className="common-para-style"> Effective Date: 16th October 2023</p>
                            <p className="common-para-style mt-3">Welcome to The Movers Online (M) Sdn Bhd ("we," "our," "us"). We are committed to protecting the privacy and personal information of our users. This Privacy Policy outlines the types of information we collect, how we use it, and how we protect it.</p>
                            <h6 className="mt-1 mb-3">TERMS OF USE</h6>
                            <p className="common-para-style">Please read these terms of use carefully before using the services offered by The Movers Online (M) Sdn Bhd (“The Movers Online“) or (“We”, “Us”, or “Our”), a company incorporated under the laws of Malaysia. These terms of use set forth the legally binding terms and conditions for your use of the website at www.themovers.my (“Website“) and the services, features, content, applications or widgets offered by The Movers Online (collectively with the site, referred to as “Services“).
                                By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may stop accessing Our Website and/or the Service.

                            </p>
                            <h6 className="mt-1 mb-3">ACCEPTANCE OF TERMS</h6>
                            <p className="common-para-style">The services that The Movers Online provides to you are subject to the following Terms of Use (“TOU”). The Movers Online reserves the right to update the TOU at any time and any changes may be made without notice to you. We recommend you to read this TOU periodically. The most current version of the TOU can be reviewed by clicking on the “Terms of Use” hypertext link located at the bottom of Our Web page.
                                By continuing to access or use Our Services after those revisions or changes become effective, you agree to be bound by the revised terms. If you do not agree to the new terms of TOU, please stop using the Website and/or Our Services.
                            </p>
                            <h6 className="mt-1 mb-3">ELIGIBILITY</h6>
                            <p className="common-para-style">Use of the Site and Services is limited to individuals and parties that can lawfully enter into contracts under the laws of Malaysia. Minors below the age of eighteen (18) years of age as prescribed under the Age of Majority Act 1971 are not allowed to use the Services without any guardian or parental supervision.
                            </p>
                            <h6 className="mt-1 mb-3">PERSONAL AND NON-COMMERCIAL USE LIMITATION</h6>
                            <p className="common-para-style">Unless otherwise specified, the Services are for your personal use only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products or services obtained from the Services.
                            </p>
                            <h6 className="mt-1 mb-3">NO UNLAWFUL OR PROHIBITED USE</h6>
                            <p className="common-para-style">As a condition of your use of the Services, you will not use the Services for any illegal and unlawful purpose or that is rendered unlawful or prohibited by Our terms, conditions, and notices. You may not use the Services in any manner that could damage, disable, overburden, or impair any The Movers Online’s server, or the network(s) connected to any of The Movers Online server, or interfere with any other party’s use and enjoyment of any Services. You may not attempt to gain unauthorized access to any Services, other accounts, computer systems or networks connected to any of The Movers Online server or to any of the Services, through hacking, password mining or any other means. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Services. At The Movers Online , your privacy is always crucial to us.This policy not only contributes to how your personal information is secured, collected and used, it also explains your choices regarding use, access and correction of your personal information.
                            </p>
                            <h6 className="mt-1 mb-3">LINKS TO OTHER WEBSITES AND MOBILE APPS</h6>
                            <p className="common-para-style">Our Service may contain links to third-party websites or mobile apps services that are not owned or controlled by The Movers Online. We have no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that The
                                Movers Online shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.
                            </p>
                            <h6 className="mt-1 mb-3">USE OF THE SERVICES</h6>
                            <p className="common-para-style">Subject to these TOU, The Movers Online may offer to provide the Services, as described more fully on the Site, and which are selected by you, solely for your own use, and not for the use or benefit of any third party. Services shall include, but not be limited to, any services The Movers Online performs for you, any applications or widgets offered by The Movers Online that you download from the Site or as provided to you by The Movers Online from time to time.
                            </p>
                            <h6 className="mt-1 mb-3">USE OF THE SERVICES</h6>
                            <p className="common-para-style">Subject to these TOU, The Movers Online may offer to provide the Services, as described more fully on the Site, and which are selected by you, solely for your own use, and not for the use or benefit of any third party. Services shall include, but not be limited to, any services The Movers Online performs for you, any applications or widgets offered by The Movers Online that you download from the Site or as provided to you by The Movers Online from time to time.
                            </p>
                            <h3 className="about-heading mb-1">INTRODUCTION</h3>
                            <h6 className="mt-1 mb-3">Information We Collect</h6>
                            <p className="common-para-style">Personal Information: When you use our service, we may collect personal information, such as your name, contact details, and payment information.
                            </p>
                            <p className="common-para-style">Usage Information: We collect data on how you use our platform, including your interactions with our website or mobile app.
                            </p>
                            <h6 className="mt-1 mb-3">Information We Collect</h6>
                            <p className="common-para-style">Device Information: We may collect information about the devices you use to access our service, such as IP addresses, device type, and browser information.
                            </p>
                            <h6 className="mt-1 mb-3 mt-2">How We Use Your Information ?</h6>
                            <h6 className="mt-1 mb-3 mt-2">We use the collected information for the following purposes:
                            </h6>
                            <p className="common-para-style">Device Information: We may collect information about the devices you use to access our service, such as IP addresses, device type, and browser information.
                            </p>
                            <p className="common-para-style">• To provide and improve our lorry booking service.</p>
                            <p className="common-para-style">• To process payments and bookings.</p>
                            <p className="common-para-style">• To communicate with you regarding your bookings and other related matters.</p>
                            <p className="common-para-style">• To analyse and enhance the user experience.</p>
                            <p className="common-para-style">• To comply with legal obligations and resolve disputes.</p>
                            <h6 className="mt-1 mb-3 mt-2">Sharing Your Information
                            </h6>
                            <h6 className="mt-1 mb-3 mt-2">We may share your information with:
                            </h6>
                            <h6 className="mt-1 mb-3 mt-2">We may share your information with:
                            </h6>
                            <p className="common-para-style">Service providers and partners who assist us in delivering our services.
                                Law enforcement or government authorities when required by law.
                                Affiliates and business partners when necessary to provide our services.</p>
                            <p className="common-para-style">Security Measures</p>
                            <p className="common-para-style">We take appropriate measures to protect your personal information. However, please be aware that no method of data transmission is entirely secure.</p>

                            <p className="common-para-style">Your Rights</p>
                            <p className="common-para-style">You have the right to access, correct, or delete your personal information. You may also opt out of receiving promotional communications.</p>
                            <p className="common-para-style">Cookies and Tracking Technologies</p>
                            <p className="common-para-style">We use cookies and similar technologies to enhance your browsing experience. You can adjust your browser settings to manage cookies.</p>
                            <h3 className="about-heading mb-1">CONTACT US</h3>
                            <p className="common-para-style">Changes to this Privacy Policy</p>
                            <p className="common-para-style">We may update this Privacy Policy from time to time. We will notify you of any significant changes through our website or app.</p>
                            <p className="common-para-style">Contact Us</p>
                            <p className="common-para-style">If you have any questions or concerns about this Privacy Policy, please contact us at themovers.my@gmail.com .</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Privacy
