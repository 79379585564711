import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { nonAuthPostAxios } from "../Utilities/commonAxios";

const initialState = {
  postItems: [],
  isLogin: false,
  isLogout: false,
  errMessage: ''
};

export const authLogin = createAsyncThunk(
  "auth/authLogin",
  async (inputData, thunkAPI) => {
    try {
      const { data: loginResponse } = await nonAuthPostAxios({
        url: `${process.env.REACT_APP_API_URL}/customers_login`,
        data: inputData,
      });
      return loginResponse;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response?.data?.message || err.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthLogoutTrue(state){
      state.postItems = [];
      state.isLogin = false;
      state.isLogout = true;
      state.errMessage = '';
    },
    setAuthLogoutFalse(state){
      state.postItems = [];
      state.isLogin = false;
      state.isLogout = false;
      state.errMessage = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state, action) => {
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.postItems = action.payload;
        state.isLogin = true;
        state.isLogout = false;
        state.errMessage = '';
      })
      .addCase(authLogin.rejected, (state, action) => {
        state.postItems = [];
        state.isLogin = false;
        state.isLogout = false;
        state.errMessage = action.payload;
      })
  }
});

export const {setAuthLogoutTrue,setAuthLogoutFalse} = authSlice.actions;
export default authSlice.reducer;
