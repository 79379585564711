import { useParams } from 'react-router'
import Bud1 from '../../img/bude1.png'
import { BookingDetailsMaster } from "../../Utilities/bookingDetailsMaster";
import { useEffect, useState } from 'react';
import { getAxios } from "../../Utilities/commonAxios";

const MybookingDeatils = () => {
    const { enBookingId } = useParams()
    const bookingId = atob(enBookingId)
    const [bookingDetailResponse, setBookingDetailResponse] = useState({})
    const [bookingDetail, setBookingDetail] = useState({})
    const getBookingDetailFromAPI = async () => {
        await getAxios({ url: `${process.env.REACT_APP_API_URL}/booking_detail?id=${bookingId}` }).then(response => {
            setBookingDetailResponse(response?.data);
            setBookingDetail(response?.data?.bookings);
        })
    }
    useEffect(() => {
        getBookingDetailFromAPI()
    }, [])
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h4 className="details-bud-head">{(bookingDetail?.service_type) ? (`${bookingDetail?.service_type} Booking`) : ('')}</h4>
                            <p className="details-bud-time">{bookingDetail?.booking_date_time}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3 col-12 d-flex align-items-stretch mb-2">
                            <div className="details-card">
                                <div className="details-card-header">
                                    <h5>Address Details</h5>
                                </div>
                                <div className="detailscard-body">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            {bookingDetail?.pickup_address && (
                                                <>
                                                    <p className="pickup-add-details mb-1">
                                                        <i
                                                            className="fa fa-map-marker me-1"
                                                            aria-hidden="true"
                                                        ></i>
                                                        Pickup Address
                                                    </p>
                                                    <p class="card-address-style">{bookingDetail?.pickup_address}</p>
                                                </>
                                            )}

                                            {bookingDetail?.drop_off_address && (
                                                <>
                                                    <p className="pickup-add-details mb-1">
                                                        <i
                                                            className="fa fa-map-marker me-1"
                                                            aria-hidden="true"
                                                        ></i>
                                                        Drop Address
                                                    </p>
                                                    <p class="card-address-style">{bookingDetail?.drop_off_address}</p>
                                                </>
                                            )}

                                        </div>
                                        {bookingDetail?.distance && (
                                            <>
                                                <div className="col-md-12 col-12 align-self-center">
                                                    <p className="mb-0">
                                                        <b>Distance:</b> {bookingDetail?.distance} Km
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3 col-12 d-flex align-items-stretch mb-2">
                            <div className="details-card">
                                <div className="details-card-header">
                                    <h5>Lorry Size</h5>
                                </div>
                                <div className="detailscard-body">
                                    <div className="row">
                                        <div className="col-md-8 col-8 align-self-center">
                                            <p className="mb-1 distance-size">
                                                1 Tonne with Driver
                                            </p>
                                        </div>
                                        <div className="col-md-4 col-4 align-self-center text-end">
                                            <img src={Bud1} className='bud-img-size' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-3 col-12 d-flex align-items-stretch mb-2">
                            <div className="details-card">
                                <div className="details-card-header">
                                    <h5>Additional Services</h5>
                                </div>
                                <div className="detailscard-body">
                                    <div className="row">
                                        <div className="col-md-8 col-8 align-self-center">
                                            <p className="mb-1 additional-srv-style">
                                                1 Tonne with Driver
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-3 col-12 d-flex align-items-stretch mb-2">
                            <div className="details-card">
                                <div className="details-card-header">
                                    <h5>Amount</h5>
                                </div>
                                <div className="detailscard-body">
                                    <div className="row">
                                        {/* <div className="col-md-6 col-6">
                                            <p className="mb-1 distance-size">
                                                1 Tonne with Driver
                                            </p>
                                        </div> */}
                                        <div className="col-md-6 col-6  text-end">
                                            MYR {bookingDetail?.total_amount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MybookingDeatils