import Swal from 'sweetalert2';

export const ConfirmationAlert = async (inputData) => {
  return await Swal.fire({
    title: "",
    text: "Are you sure want to confirm the booking?",
    // icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, confirm it!"
  });
}

export const SuccessAlert = async (successText) => {
  await Swal.fire({
    title: "Booking Received!",
    text: successText,
    icon: "success"
  });
}

export const FailureAlert = async (ErrorText) => {
  await Swal.fire({
    title: "Error!",
    text: ErrorText,
    icon: "error"
  });
}

export const DescriptionText = async (title, description) => {
  await Swal.fire({
    title: title,
    // icon: "info",
    html: description,
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    // confirmButtonText: `<i class="fa fa-thumbs-up"></i> Great!`,
    // confirmButtonAriaLabel: "Thumbs up, great!",
    // cancelButtonText: `
    //   <i class="fa fa-thumbs-down"></i>`,
    // cancelButtonAriaLabel: "Thumbs down"
  });
}
