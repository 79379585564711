import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BookingDetailsMaster } from "../../Utilities/bookingDetailsMaster";
import { GoogleMapSection } from "./googleMapSection";
import { useDispatch, useSelector } from "react-redux";
import { nonAuthPostAxios } from "../../Utilities/commonAxios";
import moment from "moment";
import { manageLoader } from "../../Reduxstore/loaderSlice";
import {
  ConfirmationAlert,
  SuccessAlert,
  FailureAlert,
} from "../../Utilities/confirmationAlert";
import { bookingIncompleted } from "../../Reduxstore/bookingSlice";

const ConfirmBooking = () => {
  const dispatch = useDispatch();
  let isAuthenticated = useSelector((state) => state.auth);
  let isBooked = useSelector((state) => state.booking);
  const navigate = useNavigate();
  let initialBookingDetails = isBooked?.initialBookingDetails
    ? isBooked?.initialBookingDetails
    : null;
  let previewBookingDetails = isBooked?.previewBookingDetails
    ? isBooked?.previewBookingDetails
    : null;
  let inCompleteBookingDetails = isBooked?.inCompleteBookingDetails
    ? isBooked?.inCompleteBookingDetails
    : null;
  const [couponCode, setCouponCode] = useState("");
  const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
  const [amount, setAmount] = useState(
    previewBookingDetails?.amount ? Number(previewBookingDetails?.amount) : 0
  );
  const [totalAmount, setTotalAmount] = useState(
    previewBookingDetails?.amount ? Number(previewBookingDetails?.amount) : 0
  );
  const [couponText, setCouponText] = useState("");
  const handleValidateCoupon = async () => {
    if (couponCode.length > 0) {
      dispatch(manageLoader(true));
      let { data: couponAPIResponse } = await nonAuthPostAxios({
        url: `${process.env.REACT_APP_API_URL}/coupon_check`,
        data: {
          user_id: isAuthenticated?.postItems?.Customer?.id,
          coupon_code: couponCode,
          total_amount: previewBookingDetails?.amount,
        },
      });
      dispatch(manageLoader(false));
      if (couponAPIResponse.Api_success) {
        if (couponAPIResponse?.Coupon?.rule_type === "1") {
          setCouponDiscountAmount(Number(couponAPIResponse?.Coupon?.rule));
          setTotalAmount(
            Number(previewBookingDetails?.amount) -
              Number(couponAPIResponse?.Coupon?.rule)
          );
          setCouponText(
            `${couponCode} - Flat ${couponAPIResponse?.Coupon?.rule} discount successfully!`
          );
        } else if (couponAPIResponse?.Coupon?.rule_type === "2") {
          let disCountedAmount = Math.round(
            Number(previewBookingDetails?.amount) *
              (Number(couponAPIResponse?.Coupon?.rule) / 100)
          );
          setCouponDiscountAmount(disCountedAmount);
          setTotalAmount(
            Number(previewBookingDetails?.amount) - disCountedAmount
          );
          setCouponText(
            `${couponCode} - ${couponAPIResponse?.Coupon?.rule}% discount successfully!`
          );
        }
      } else {
        setCouponDiscountAmount(0);
        setTotalAmount(previewBookingDetails?.amount);
        setCouponText(`${couponCode} - ${couponAPIResponse?.message}`);
      }
    } else {
      toast.error("Invalid coupon details!");
    }
  };
  // Authenticate Modal
  const [isAuthenticateModal, setIsAuthenticateModal] = useState(null);
  const isAuthenticateModalRef = useRef(null);
  useEffect(() => {
    if (!initialBookingDetails || !previewBookingDetails) {
      toast.error("Invalid booking details!");
      navigate("/");
    } else {
      if (isAuthenticateModalRef.current) {
        setIsAuthenticateModal(
          new window.bootstrap.Modal(isAuthenticateModalRef.current)
        );
      }
    }
  }, []);
  const showModal = () => {
    if (isAuthenticateModal) {
      isAuthenticateModal.show();
    }
  };
  const hideModal = () => {
    if (isAuthenticateModal) {
      isAuthenticateModal.hide();
    }
  };
  const modalNavigation = async (redirectURL) => {
    await hideModal();
    navigate(`/${redirectURL}`);
  };
  const handleBookingSubmit = async () => {
    let formData = {};
    let apiURL = "";
    if (
      initialBookingDetails?.bookingType === "budget" ||
      initialBookingDetails?.bookingType === "premium"
    ) {
      formData = {
        customer_id: isAuthenticated?.postItems?.Customer?.id,
        booking_type:
          BookingDetailsMaster[initialBookingDetails.bookingType]["type"],
        service_type:
          BookingDetailsMaster[initialBookingDetails.bookingType]["type"],
        booking_date_time: moment().format("YYYY-MM-DD hh:mm:ss"),
        pickup_address: initialBookingDetails?.fromPlace,
        drop_off_address: initialBookingDetails?.toPlace,
        pickup_latitude: initialBookingDetails?.fromLatLong?.lat,
        pickup_longitude: initialBookingDetails?.fromLatLong?.lng,
        drop_latitude: initialBookingDetails?.toLatLong?.lat,
        drop_longitude: initialBookingDetails?.toLatLong?.lng,
        distance: initialBookingDetails?.distance?.text,
        vehicle_type: previewBookingDetails?.details.id,
        amount: previewBookingDetails?.details.base_price,
        coupon_code: couponCode,
        vehicle_amount: amount,
        total_amount: totalAmount,
      };
      if (initialBookingDetails?.bookingType === "budget") {
        apiURL = `${process.env.REACT_APP_API_URL}/booking_budget_submit`;
      } else if (initialBookingDetails?.bookingType === "premium") {
        apiURL = `${process.env.REACT_APP_API_URL}/booking_premium_submit`;
      }
    } else if (initialBookingDetails?.bookingType === "disposal") {
      formData = {
        customer_id: isAuthenticated?.postItems?.Customer?.id,
        service_type:
          BookingDetailsMaster[initialBookingDetails.bookingType]["type"],
        booking_date_time: moment().format("YYYY-MM-DD hh:mm:ss"),
        pickup_address: initialBookingDetails?.fromPlace,
        pickup_latitude: initialBookingDetails?.fromLatLong?.lat,
        pickup_longitude: initialBookingDetails?.fromLatLong?.lng,
        amount: previewBookingDetails?.details.base_price,
        vehicle_type: previewBookingDetails?.details.id,
        coupon_code: couponCode,
        total_amount: totalAmount,
      };
      apiURL = `${process.env.REACT_APP_API_URL}/booking_disposal_submit`;
    } else if (initialBookingDetails?.bookingType === "manpower") {
      formData = {
        customer_id: isAuthenticated?.postItems?.Customer?.id,
        service_type:
          BookingDetailsMaster[initialBookingDetails.bookingType]["type"],
        booking_date_time: moment().format("YYYY-MM-DD hh:mm:ss"),
        pickup_address: initialBookingDetails?.fromPlace,
        pickup_latitude: initialBookingDetails?.fromLatLong?.lat,
        pickup_longitude: initialBookingDetails?.fromLatLong?.lng,
        amount: previewBookingDetails?.details.base_price,
        manpower_count: previewBookingDetails?.details.count,
        coupon_code: couponCode,
        manpower_hour_count: previewBookingDetails?.details.duration,
        description: previewBookingDetails?.details?.description,
        total_amount: totalAmount,
      };
      apiURL = `${process.env.REACT_APP_API_URL}/booking_manpower_submit`;
    }
    if (!isAuthenticated.isLogin) {
      let inCompleteBookingDetails = { apiURL, formData };
      showModal();
      await dispatch(bookingIncompleted(inCompleteBookingDetails));
    } else {
      let confirmationResult = await ConfirmationAlert();
      if (confirmationResult.isConfirmed) {
        await handleFinalBookingSubmit(apiURL, formData);
      } else {
        await FailureAlert("Unable to book!");
      }
    }
  };
  const handleFinalBookingSubmit = async (apiURL, formData) => {
    dispatch(manageLoader(true));
    await nonAuthPostAxios({
      url: apiURL,
      data: formData,
    }).then(async (res) => {
      dispatch(manageLoader(false));
      if (res.data?.Api_success) {
        SuccessAlert("You're Booked with The Movers Online!");
        navigate("/my-bookings");
      } else {
        FailureAlert(res.data?.message);
      }
    });
  };
  return (
    <>
      <section className="common-top-style mybooking-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="about-heading">
                {initialBookingDetails?.bookingType
                  ? BookingDetailsMaster[initialBookingDetails.bookingType][
                      "label"
                    ]
                  : ""}{" "}
                Details
              </h3>
            </div>
          </div>
          <div className="row">
            {(initialBookingDetails?.bookingType === "budget" ||
              initialBookingDetails?.bookingType === "premium") && (
              <>
                <div className="col-xl-4 col-md-6 col-12">
                  <div className="mt-2 mb-3">
                    <GoogleMapSection
                      center={initialBookingDetails?.toLatLong}
                      fromLatLong={initialBookingDetails?.fromLatLong}
                      toLatLong={initialBookingDetails?.toLatLong}
                      distance={initialBookingDetails?.distance}
                      duration={initialBookingDetails?.duration}
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-md-6 col-12">
                  <div className="card budect-booking-card">
                    <div className="card-header budgect-header">
                      <h4 className="budect-heading-style">Address Details</h4>
                    </div>
                    <div className="card-body card-body-budgect">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address">Pickup Address</h4>
                          <p className="bud-pickep-address1">
                            {initialBookingDetails?.fromPlace}
                          </p>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address">Distance</h4>
                          <h4 className="bud-pickep-address-kilo">
                            {initialBookingDetails?.distance?.text}
                          </h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <h4 className="bud-pickep-address">Drop Address</h4>
                          <p className="bud-pickep-address1 mb-1">
                            {initialBookingDetails?.toPlace}
                          </p>
                        </div>
                      </div>
                      <hr />
                      {previewBookingDetails?.details?.description && (
                        <>
                          <div className="row">
                            <div className="col-md-12 col-12 text-start mt-1">
                              <h4 className="bud-pickep-address">
                                Service Description
                              </h4>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    previewBookingDetails?.details?.description,
                                }}
                              ></div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">Amount</h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            MYR {amount}
                          </h4>
                        </div>
                      </div>
                      <hr />
                      {couponDiscountAmount !== 0 && (
                        <>
                          <div className="row">
                            <div className="col-md-12 col-12">
                              <h4 className="bud-pickep-address-amount">
                                Discount
                              </h4>
                            </div>
                            <div className="col-md-12 col-12 text-end">
                              <h4 className="bud-pickep-address-amount1">
                                {" "}
                                MYR {couponDiscountAmount}
                              </h4>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <div className="row mt-2">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">Total</h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            {" "}
                            MYR {totalAmount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {initialBookingDetails?.bookingType === "disposal" && (
              <>
                <div className="col-xl-4 col-md-6 col-12">
                  <div className="mt-2 mb-3">
                    <GoogleMapSection
                      center={initialBookingDetails?.fromLatLong}
                      fromLatLong={initialBookingDetails?.fromLatLong}
                      toLatLong={initialBookingDetails?.toLatLong}
                      distance={initialBookingDetails?.distance}
                      duration={initialBookingDetails?.duration}
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-md-6 col-12">
                  <div className="card budect-booking-card">
                    <div className="card-header budgect-header">
                      <h4 className="budect-heading-style">Address Details</h4>
                    </div>
                    <div className="card-body card-body-budgect">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address">Pickup Address</h4>
                          <p className="bud-pickep-address1">
                            {initialBookingDetails?.fromPlace}
                          </p>
                        </div>
                      </div>
                      <hr />
                      {previewBookingDetails?.details?.description && (
                        <>
                          <div className="row">
                            <div className="col-md-12 col-12 text-start mt-1">
                              <h4 className="bud-pickep-address">
                                Service Description
                              </h4>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    previewBookingDetails?.details?.description,
                                }}
                              ></div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">Amount</h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            MYR {amount}
                          </h4>
                        </div>
                      </div>
                      <hr />
                      {couponDiscountAmount !== 0 && (
                        <>
                          <div className="row">
                            <div className="col-md-12 col-12">
                              <h4 className="bud-pickep-address-amount">
                                Discount
                              </h4>
                            </div>
                            <div className="col-md-12 col-12 text-end">
                              <h4 className="bud-pickep-address-amount1">
                                {" "}
                                MYR {couponDiscountAmount}
                              </h4>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <div className="row mt-2">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">Total</h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            {" "}
                            MYR {totalAmount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {initialBookingDetails?.bookingType === "manpower" && (
              <>
                <div className="col-xl-4 col-md-6 col-12">
                  <div className="mt-2 mb-3">
                    <GoogleMapSection
                      center={initialBookingDetails?.fromLatLong}
                      fromLatLong={initialBookingDetails?.fromLatLong}
                      toLatLong={initialBookingDetails?.toLatLong}
                      distance={initialBookingDetails?.distance}
                      duration={initialBookingDetails?.duration}
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-md-6 col-12">
                  <div className="card budect-booking-card">
                    <div className="card-header budgect-header">
                      <h4 className="budect-heading-style">Address Details</h4>
                    </div>
                    <div className="card-body card-body-budgect">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address">Pickup Address</h4>
                          <p className="bud-pickep-address1 mb-0">
                            {initialBookingDetails?.fromPlace}
                          </p>
                        </div>
                      </div>
                      <hr />
                      {previewBookingDetails?.details?.description && (
                        <>
                          <div className="row">
                            <div className="col-md-12 col-12 text-start mt-1">
                              <h4 className="bud-pickep-address">
                                Service Description
                              </h4>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    previewBookingDetails?.details?.description,
                                }}
                              ></div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">
                            Manpower Count
                          </h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            {previewBookingDetails?.details?.count}
                          </h4>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">
                            Manpower Duration
                          </h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            {previewBookingDetails?.details?.duration}
                          </h4>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">Amount</h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            {" "}
                            MYR {amount}
                          </h4>
                        </div>
                      </div>
                      <hr />
                      {couponDiscountAmount !== 0 && (
                        <>
                          <div className="row">
                            <div className="col-md-12 col-12">
                              <h4 className="bud-pickep-address-amount">
                                Discount
                              </h4>
                            </div>
                            <div className="col-md-12 col-12 text-end">
                              <h4 className="bud-pickep-address-amount1">
                                {" "}
                                MYR {couponDiscountAmount}
                              </h4>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <div className="row mt-2">
                        <div className="col-md-6 col-6">
                          <h4 className="bud-pickep-address-amount">Total</h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                          <h4 className="bud-pickep-address-amount1">
                            {" "}
                            MYR {totalAmount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-xl-3 col-md-6 col-12 mobil-bud-bottom">
              <form>
                <div className="form-group">
                  <label htmlFor="couponCode custom-label">
                    Enter Coupon Code
                  </label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    id="couponCode"
                    aria-describedby="couponCode"
                    placeholder=""
                    onChange={(e) => setCouponCode(e.target.value)}
                    value={couponCode}
                  />
                  {couponText && (
                    <p
                      className="distance-style mb-0 ms-1"
                      sx={{ fontWeight: 700 }}
                    >
                      {couponText}
                    </p>
                  )}
                  <button
                    type="button"
                    className="booknoe-btn"
                    onClick={handleValidateCoupon}
                  >
                    Verify Coupon
                  </button>
                  <button
                    type="button"
                    className="booknoe-btn mb-3"
                    onClick={handleBookingSubmit}
                  >
                    Book Now
                  </button>
                </div>
              </form>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 col-6 ">
                <Link to={"/initiate-booking"} className="bud-link-style">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="isAuthenticateModal"
        tabIndex="-1"
        aria-labelledby="isAuthenticateModalLabel"
        aria-hidden="true"
        ref={isAuthenticateModalRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content auth-model-custom">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <h5 className="modal-title" id="isAuthenticateModalLabel">
                You have not logged in!
              </h5>
              <br></br>
              <br></br>
              <div className="mt-3 mb-3">
                <Link
                  to="#"
                  className="btn btn-primary me-2 custom-red-btn"
                  onClick={() => modalNavigation("register")}
                >
                  Register
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary me-2 custom-red-btn"
                  onClick={() => modalNavigation("login")}
                >
                  Login
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary custom-red-btn"
                  onClick={() => modalNavigation("register")}
                >
                  Book as Guest
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmBooking;
