import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  status: 'idle',
  error: null
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    manageLoader(state, {payload}) {
      state.isLoading = payload
    }
  }
})

export const { manageLoader } = loaderSlice.actions;
export default loaderSlice.reducer