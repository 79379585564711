export const BookingDetailsMaster = {
  'budget': {
    'type':1,
    'name':'Budget Moving',
    'label':'Budget Booking'
  },
  'premium': {
    'type':2,
    'name':'Premium Moving',
    'label':'Premium Booking'
  },
  'disposal': {
    'type':3,
    'name':'Disposal Service',
    'label':'Disposal Service'
  },
  'manpower': {
    'type':5,
    'name':'Manpower Service',
    'label':'Manpower Service'
  }
}