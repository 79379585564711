// import Serviceimg2 from '../../img/service2.png'
import Readyimg from '../../img/ready-img.png'
import Serviceimg4 from '../../img/service4.png'

const PremimumMoving = () => {
    return (
        <>
             <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="local-heading">All in Service Moving Package</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <img src={Serviceimg4} className='w-100' />
                        </div>
                        <div className="col-md-5 col-12">
                            <h5 className='price-style1'>PRICE FROM: <span className='pricecolor-style1'>RM 600</span></h5>
                            <h6 className='pricecolor-style1'>What Included in All in Service Moving Package</h6>
                            <p className='mb-1'>• Lorry of your choice – 1 tonne (10 ft) / 3 tonne (14 ft) / 5 tonne (17ft)</p>
                            <p className='mb-1'>• Trained & Skilled Approved Crew (2 Man Power-1 Tonne Package)(3 Man Power-3 & 5 Tonne Package)</p>
                            <p className='mb-1'>• FREE ON-SITE Visit Prior to the Moving Date</p>
                            <p className='mb-1'>• NO Maximum Distance Coverage within Klang Valley</p>
                            <p className='mb-1'>• By subscribing our all in service moving package, ALL Big Item to be wrapped (SHRINK & BUBBLE)</p>
                            <p className='mb-1'>• Unlimited Furniture Dismantling & Reassembling limited to One Trip</p>
                            <p className='mb-1'>• FREE 20 Boxes for 3 Tonne & 5 Tonne package [10 Box for 1 Tonne Package]</p>
                        </div>
                        <div className="col-md-3 col-12 d-none d-xl-block">
                            <img src={Readyimg} className='w-100' />
                            <p className='mb-1 mt-3'>No 2, Jalan PUJ 9/23, Taman Puncak Jalil, 43300 Seri Kembangan, Selangor</p>
                            <p className='mb-1 budgect-color-style'>+60123244261</p>
                            <p className='mb-1 budgect-color-style'>info@themovers.my</p>
                            <h5 className='additional-style mb-2'>Got Any Question?</h5>
                            <p>If you are having any questions, please feel free to ask.</p>
                        </div>
                    </div>
                    <div className="row seprate-top-style">
                        <div className="col-md-8 col-12">
                           
                            <h5 className='pricecolor-style1 mb-2 mt-3'>How it Works</h5>
                            <p className='mb-1'>• Submit your request
                            Our Customer Service Representative will contact you to schedule an appointment.</p>
                            <p className='mb-1'>• Pay via Online Banking or directly to the vendor upon completion of the service.</p>
                            <h5 className='pricecolor-style1 mb-2 mt-3'>Things to Take Note</h5>
                            <p className='mb-1'>• Not applicable for moving of piano or safeboxes – Any Heavy Item weight more than 50 KG</p>
                            <p className='mb-1'>• Loading Bay Charges is Applicable in the Event the Distance is more than 15m from/to the loading lift or to the unit</p>
                            <p className='mb-1'>• Our all in service moving package does not cover NOLIFT Charges [MYR 30/Floor For 1 Tonne Package] [ MYR 70/Floor For 3 Tonne & MYR 100/Floor For 5 Tonne Package]</p>
                            <p className='mb-1'>• Building Management Approval Letter to be obtained Prior to the Moving Day</p>
                            <p className='mb-1'>• Movers Have Rights to Charge 30% of the Booking Amount as cancellation Fee for cancellation lesser than 24 hours.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <h5 className='pricecolor-style1 mb-2 mt-3'>Pricing</h5>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>All in Service Moving Package</th>
                                        <th>BASE PRICE (0KM – 10KM)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>1 Tonne with 3 Man Power</th>
                                        <td>600</td>
                                    </tr>
                                    <tr>
                                        <th>3 Tonne with 4 Man Power</th>
                                        <td>1100</td>
                                    </tr>
                                    <tr>
                                        <th>5 Tonne with 4 Man Power</th>
                                        <td>1300</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <h5 className='additional-style'>[LOCAL ONLY]</h5>

                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-3 col-12 d-block d-xl-none mobile-d-block">
                            <img src={Readyimg} className='w-100' />
                            <p className='mb-1 mt-3'>No 2, Jalan PUJ 9/23, Taman Puncak Jalil, 43300 Seri Kembangan, Selangor</p>
                            <p className='mb-1 budgect-color-style'>+60123244261</p>
                            <p className='mb-1 budgect-color-style'>info@themovers.my</p>
                            <h5 className='additional-style mb-2'>Got Any Question?</h5>
                            <p>If you are having any questions, please feel free to ask.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PremimumMoving