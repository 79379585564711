import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { useState, useEffect } from "react";

export const GoogleMapSection = (props) => {
  const mapContainerStyle = { height: "250px" };
  const { center, fromLatLong, toLatLong, distance, duration } = props;
  const [directionsResponse, setDirectionsResponse] = useState(null);

  useEffect(() => {
    if (Object.keys(fromLatLong).length > 0 && Object.keys(toLatLong).length > 0) {
      calculateRoute();
    }
  }, [fromLatLong, toLatLong]);
  const calculateRoute = () => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: fromLatLong,
        destination: toLatLong,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  return (
    <>
      <GoogleMap mapContainerStyle={mapContainerStyle} zoom={10} center={center}>
        {Object.keys(fromLatLong).length > 0 && <Marker position={fromLatLong} />}
        {Object.keys(toLatLong).length > 0 && <Marker position={toLatLong} />}
        {directionsResponse && (
          <DirectionsRenderer
            options={{
              directions: directionsResponse,
            }}
          />
        )}
      </GoogleMap>
      {distance && duration && (
        <div className="d-flex mt-1 justify-content-center">
          <div className="me-2">
            <p className="distance-style">Distance: {distance.text}</p>
          </div>
          {" | "}
          <div className="me-2">
            <p className="distance-style mb-0 ms-1">Estimated Travel Time: {duration}</p>
          </div>
        </div>
      )}
    </>
  );
};
